import { StaticImage } from "gatsby-plugin-image";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import { HeadFC } from "gatsby";
import * as React from "react";

const ServicesPage = () => {
  return (
      <>
        <main>
          <div className="section">
            <StaticImage src="../images/hvac_work.jpg" alt="HVAC toolbox" className="section__background" />
            <div className="section__content">
              <Header />
              <div className="pure-g discovery">
                <div className="pure-u-1 pure-u-sm-3-4 pure-u-lg-1-2">
                  <h1 className="discovery__title"><a id="contact-us">Our Works</a></h1>
                  <div className="discovery__description">
                    <div className="pure-u-1">
                      <p>Our expert technicians are very capable of <b>installing</b>, <b>maintaining</b> and <b>repairing</b> a multitude of HVAC systems.</p>
                      <p>We work diligently to provide speedy services while maintaining high standards and reasonable rates.</p>
                    </div>
                    <div className="service pure-u-1-3 pure-u-sm-1-2">
                      <StaticImage
                          src="../images/services/ac_systems.jpg"
                          alt="AC systems"
                          height={80}
                          width={80}
                          objectFit="cover"
                          className="service__image" />
                      <span className="service__label">AC systems</span>
                    </div>
                    <div className="service pure-u-1-3 pure-u-sm-1-2">
                      <StaticImage
                          src="../images/services/ductworks.png"
                          alt="Ductworks"
                          height={80}
                          width={80}
                          objectFit="cover"
                          className="service__image" />
                      <span className="service__label">Ductworks</span>
                    </div>
                    <div className="service pure-u-1-3 pure-u-sm-1-2">
                      <StaticImage
                          src="../images/services/electrical_by_side.jpg"
                          alt="Electrical"
                          height={80}
                          width={80}
                          objectFit="cover"
                          className="service__image" />
                      <span className="service__label">Electrical</span>
                    </div>
                    <div className="service pure-u-1-3 pure-u-sm-1-2">
                      <StaticImage
                          src="../images/services/furnace.jpg"
                          alt="Furnace"
                          height={80}
                          width={80}
                          objectFit="cover"
                          className="service__image" />
                      <span className="service__label">Furnace</span>
                    </div>
                    <div className="service pure-u-1-3 pure-u-sm-1-2">
                      <StaticImage
                          src="../images/services/minislipt.jpg"
                          alt="Minislipt"
                          height={80}
                          width={80}
                          objectFit="cover"
                          className="service__image" />
                      <span className="service__label">Minislipt</span>
                    </div>
                    <div className="service pure-u-1-3 pure-u-sm-1-2">
                      <StaticImage
                          src="../images/services/zone_control.jpg"
                          alt="Zone control"
                          height={80}
                          width={80}
                          objectFit="cover"
                          className="service__image" />
                      <span className="service__label">Zone control</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </>
  )
}

export default ServicesPage

export const Head: HeadFC = () => <title>Sunrise HVAC - Services</title>
